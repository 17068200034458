import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import { EnfisQuasar } from '@enfis/quasar';
import skin from './styles/skin/skin.js';

createApp(App)
    .use(Quasar, quasarUserOptions)
    .use(i18n)
    .use(store)
    .use(router)
    .use(EnfisQuasar, {
        config: {
            io: { baseURL: window.config.enfisBaseUrl },
            user: {
                roles: {
                    ROLE_USERS_MANAGER: 1,
                    ROLE_SETTINGS_MANAGER: 2,
                    ROLE_SYSTEM_MANAGER: 3,
                    ROLE_LOG_ACCESS: 4,
                    ROLE_CHANGE_ACCESS: 5,
                    ROLE_MAIN_MANAGER: 6,
                    ROLE_CONTENT_MANAGER: 7,
                },
            },
            loading: {
                spinnerColor: skin.e_color,
            },
            props: {
                ...skin,
            },
        },
    })
    .mount('#app');
