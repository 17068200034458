import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import LoginLayout from '../layouts/LoginLayout.vue';
import MainLayout from '../layouts/MainLayout.vue';

const routes = [
    {
        name: 'root',
        path: '/:pathMatch(.*)*',
        beforeEnter(to, from) {
            return false;
        },
    },
];
const loginRoutes = [{ name: 'login', path: '/:pathMatch(.*)*', component: LoginLayout }];
const mainRoutes = [
    {
        name: 'root',
        path: '/',
        component: MainLayout,
        // prettier-ignore
        children: [
            { name: 'main', path: '/', component: () => import(/* webpackChunkName: "main" */ '../views/MainView.vue') },
            { name: 'profile', path: '/profile', component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue') },
            { name: 'client', path: '/client', component: () => import(/* webpackChunkName: "client" */ '../views/Client/ClientView.vue') },
            { name: 'client-detail', path: '/client/detail/:id', component: () => import(/* webpackChunkName: "client-detail" */ '../views/Client/ClientDetailView.vue'), meta: { persistent: ['project', 'investor'] } },
            { name: 'client-edit', path: '/client/edit/:id?', component: () => import(/* webpackChunkName: "client-edit" */ '../views/Client/ClientEditView.vue'), meta: { persistent: ['project', 'investor'] } },
            { name: 'client-client_financial_indicator-detail', path: '/client/client_financial_indicator/detail/:clientId/:id', component: () => import(/* webpackChunkName: "client-client_financial_indicator-detail" */ '../views/Client/ClientFinancialIndicator/ClientFinancialIndicatorDetailView.vue'), meta: { persistent: ['project', 'investor'] } },
            { name: 'client-client_financial_indicator-edit', path: '/client/client_financial_indicator/edit/:clientId/:id?', component: () => import(/* webpackChunkName: "client-client_financial_indicator-edit" */ '../views/Client/ClientFinancialIndicator/ClientFinancialIndicatorEditView.vue'), meta: { persistent: ['project', 'investor'] } },
            { name: 'client-client_questionnaire-detail', path: '/client/client_questionnaire/detail/:clientId/:id', component: () => import(/* webpackChunkName: "client-client_questionnaire-detail" */ '../views/Client/ClientQuestionnaire/ClientQuestionnaireDetailView.vue'), meta: { persistent: ['project', 'investor'] } },
            { name: 'client-client_questionnaire-edit', path: '/client/client_questionnaire/edit/:clientId/:id?', component: () => import(/* webpackChunkName: "client-client_questionnaire-edit" */ '../views/Client/ClientQuestionnaire/ClientQuestionnaireEditView.vue'), meta: { persistent: ['project', 'investor'] } },
            { name: 'client-client_declaration-detail', path: '/client/client_declaration/detail/:clientId/:id', component: () => import(/* webpackChunkName: "client-client_declaration-detail" */ '../views/Client/ClientDeclaration/ClientDeclarationDetailView.vue'), meta: { persistent: ['project', 'investor'] } },
            { name: 'client-client_declaration-edit', path: '/client/client_declaration/edit/:clientId/:id?', component: () => import(/* webpackChunkName: "client-client_declaration-edit" */ '../views/Client/ClientDeclaration/ClientDeclarationEditView.vue'), meta: { persistent: ['project', 'investor'] } },
            { name: 'client-client_verification-detail', path: '/client/client_verification/detail/:clientId/:id', component: () => import(/* webpackChunkName: "client-client_verification-detail" */ '../views/Client/ClientVerification/ClientVerificationDetailView.vue'), meta: { persistent: ['project', 'investor'] } },
            { name: 'client-client_verification-edit', path: '/client/client_verification/edit/:clientId/:id?', component: () => import(/* webpackChunkName: "client-client_verification-edit" */ '../views/Client/ClientVerification/ClientVerificationEditView.vue'), meta: { persistent: ['project', 'investor'] } },
            { name: 'client-document-detail', path: '/client/document/detail/:clientId/:id', component: () => import(/* webpackChunkName: "client-document-detail" */ '../views/Client/Document/DocumentDetailView.vue'), meta: { persistent: ['project', 'investor'] } },
            { name: 'client-document-edit', path: '/client/document/edit/:clientId/:id?', component: () => import(/* webpackChunkName: "client-document-edit" */ '../views/Client/Document/DocumentEditView.vue'), meta: { persistent: ['project', 'investor'] } },
            { name: 'project', path: '/project', component: () => import(/* webpackChunkName: "project" */ '../views/Project/ProjectView.vue') },
            { name: 'project-detail', path: '/project/detail/:id', component: () => import(/* webpackChunkName: "project-detail" */ '../views/Project/ProjectDetailView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-edit', path: '/project/edit/:id?', component: () => import(/* webpackChunkName: "project-edit" */ '../views/Project/ProjectEditView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-investor-detail', path: '/project/investor/detail/:projectId/:id', component: () => import(/* webpackChunkName: "project-investor-detail" */ '../views/Project/Investor/InvestorDetailView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-investor-edit', path: '/project/investor/edit/:projectId/:id?', component: () => import(/* webpackChunkName: "project-investor-edit" */ '../views/Project/Investor/InvestorEditView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-transaction-detail', path: '/project/transaction/detail/:projectId/:id', component: () => import(/* webpackChunkName: "project-transaction-detail" */ '../views/Project/Transaction/TransactionDetailView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-transaction-edit', path: '/project/transaction/edit/:projectId/:id?', component: () => import(/* webpackChunkName: "project-transaction-edit" */ '../views/Project/Transaction/TransactionEditView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-project_progress-detail', path: '/project/project_progress/detail/:projectId/:id', component: () => import(/* webpackChunkName: "project-project_progress-detail" */ '../views/Project/ProjectProgress/ProjectProgressDetailView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-project_progress-edit', path: '/project/project_progress/edit/:projectId/:id?', component: () => import(/* webpackChunkName: "project-project_progress-edit" */ '../views/Project/ProjectProgress/ProjectProgressEditView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-project_news-detail', path: '/project/project_news/detail/:projectId/:id', component: () => import(/* webpackChunkName: "project-project_news-detail" */ '../views/Project/ProjectNews/ProjectNewsDetailView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-project_news-edit', path: '/project/project_news/edit/:projectId/:id?', component: () => import(/* webpackChunkName: "project-project_news-edit" */ '../views/Project/ProjectNews/ProjectNewsEditView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-project_question-detail', path: '/project/project_question/detail/:projectId/:id', component: () => import(/* webpackChunkName: "project-project_question-detail" */ '../views/Project/ProjectQuestion/ProjectQuestionDetailView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-project_question-edit', path: '/project/project_question/edit/:projectId/:id?', component: () => import(/* webpackChunkName: "project-project_question-edit" */ '../views/Project/ProjectQuestion/ProjectQuestionEditView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-project_complaint-detail', path: '/project/project_complaint/detail/:projectId/:id', component: () => import(/* webpackChunkName: "project-project_complaint-detail" */ '../views/Project/ProjectComplaint/ProjectComplaintDetailView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-project_complaint-edit', path: '/project/project_complaint/edit/:projectId/:id?', component: () => import(/* webpackChunkName: "project-project_complaint-edit" */ '../views/Project/ProjectComplaint/ProjectComplaintEditView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-project_contract-detail', path: '/project/project_contract/detail/:projectId/:id', component: () => import(/* webpackChunkName: "project-project_contract-detail" */ '../views/Project/ProjectContract/ProjectContractDetailView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-project_contract-edit', path: '/project/project_contract/edit/:projectId/:id?', component: () => import(/* webpackChunkName: "project-project_contract-edit" */ '../views/Project/ProjectContract/ProjectContractEditView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-document-detail', path: '/project/document/detail/:projectId/:id', component: () => import(/* webpackChunkName: "project-document-detail" */ '../views/Project/Document/DocumentDetailView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-document-edit', path: '/project/document/edit/:projectId/:id?', component: () => import(/* webpackChunkName: "project-document-edit" */ '../views/Project/Document/DocumentEditView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-gallery_entry-detail', path: '/project/gallery_entry/detail/:projectId/:galleryId/:id', component: () => import(/* webpackChunkName: "project-gallery_entry-detail" */ '../views/Project/GalleryEntry/GalleryEntryDetailView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'project-gallery_entry-edit', path: '/project/gallery_entry/edit/:projectId/:galleryId/:id?', component: () => import(/* webpackChunkName: "project-gallery_entry-edit" */ '../views/Project/GalleryEntry/GalleryEntryEditView.vue'), meta: { persistent: ['client', 'type'] } },
            { name: 'transaction', path: '/transaction', component: () => import(/* webpackChunkName: "transaction" */ '../views/Transaction/TransactionView.vue') },
            { name: 'transaction-detail', path: '/transaction/detail/:id', component: () => import(/* webpackChunkName: "transaction-detail" */ '../views/Transaction/TransactionDetailView.vue') },
            { name: 'transaction-edit', path: '/transaction/edit/:id?', component: () => import(/* webpackChunkName: "transaction-edit" */ '../views/Transaction/TransactionEditView.vue') },
            { name: 'declaration', path: '/declaration', component: () => import(/* webpackChunkName: "declaration" */ '../views/Declaration/DeclarationView.vue') },
            { name: 'declaration-detail', path: '/declaration/detail/:id', component: () => import(/* webpackChunkName: "declaration-detail" */ '../views/Declaration/DeclarationDetailView.vue') },
            { name: 'declaration-edit', path: '/declaration/edit/:id?', component: () => import(/* webpackChunkName: "declaration-edit" */ '../views/Declaration/DeclarationEditView.vue') },
            { name: 'questionnaire', path: '/questionnaire', component: () => import(/* webpackChunkName: "questionnaire" */ '../views/Questionnaire/QuestionnaireView.vue') },
            { name: 'questionnaire-detail', path: '/questionnaire/detail/:id', component: () => import(/* webpackChunkName: "questionnaire-detail" */ '../views/Questionnaire/QuestionnaireDetailView.vue') },
            { name: 'questionnaire-edit', path: '/questionnaire/edit/:id?', component: () => import(/* webpackChunkName: "questionnaire-edit" */ '../views/Questionnaire/QuestionnaireEditView.vue') },
            { name: 'questionnaire-questionnaire_entry-detail', path: '/questionnaire/questionnaire_entry/detail/:questionnaireId/:id', component: () => import(/* webpackChunkName: "questionnaire-questionnaire_entry-detail" */ '../views/Questionnaire/QuestionnaireEntry/QuestionnaireEntryDetailView.vue') },
            { name: 'questionnaire-questionnaire_entry-edit', path: '/questionnaire/questionnaire_entry/edit/:questionnaireId/:id?', component: () => import(/* webpackChunkName: "questionnaire-questionnaire_entry-edit" */ '../views/Questionnaire/QuestionnaireEntry/QuestionnaireEntryEditView.vue') },
            { name: 'verification', path: '/verification', component: () => import(/* webpackChunkName: "verification" */ '../views/Verification/VerificationView.vue') },
            { name: 'verification-detail', path: '/verification/detail/:id', component: () => import(/* webpackChunkName: "verification-detail" */ '../views/Verification/VerificationDetailView.vue') },
            { name: 'verification-edit', path: '/verification/edit/:id?', component: () => import(/* webpackChunkName: "verification-edit" */ '../views/Verification/VerificationEditView.vue') },
            { name: 'mail', path: '/mail', component: () => import(/* webpackChunkName: "mail" */ '../views/Mail/MailView.vue') },
            { name: 'mail-detail', path: '/mail/detail/:id', component: () => import(/* webpackChunkName: "mail-detail" */ '../views/Mail/MailDetailView.vue') },
            { name: 'log', path: '/log', component: () => import(/* webpackChunkName: "log" */ '../views/Log/LogView.vue') },
            { name: 'log-detail', path: '/log/detail/:id', component: () => import(/* webpackChunkName: "log-detail" */ '../views/Log/LogDetailView.vue') },
            { name: 'change', path: '/change', component: () => import(/* webpackChunkName: "change" */ '../views/Change/ChangeView.vue') },
            { name: 'change-detail', path: '/change/detail/:id', component: () => import(/* webpackChunkName: "change-detail" */ '../views/Change/ChangeDetailView.vue') },
            
            // Prezentačný web
            { name: 'advice', path: '/advice', component: () => import(/* webpackChunkName: "advice" */ '../views/Advice/AdviceView.vue') },
            { name: 'advice-detail', path: '/advice/detail/:id', component: () => import(/* webpackChunkName: "advice-detail" */ '../views/Advice/AdviceDetailView.vue') },
            { name: 'advice-edit', path: '/advice/edit/:id?', component: () => import(/* webpackChunkName: "advice-edit" */ '../views/Advice/AdviceEditView.vue') },
            { name: 'article', path: '/article', component: () => import(/* webpackChunkName: "article" */ '../views/Article/ArticleView.vue') },
            { name: 'article-detail', path: '/article/detail/:id', component: () => import(/* webpackChunkName: "article-detail" */ '../views/Article/ArticleDetailView.vue') },
            { name: 'article-edit', path: '/article/edit/:id?', component: () => import(/* webpackChunkName: "article-edit" */ '../views/Article/ArticleEditView.vue') },
            { name: 'article-image-detail', path: '/article/image/detail/:articleId/:id', component: () => import(/* webpackChunkName: "article-image-detail" */ '../views/Article/Image/ImageDetailView.vue') },
            { name: 'article-image-edit', path: '/article/image/edit/:articleId/:id?', component: () => import(/* webpackChunkName: "article-image-edit" */ '../views/Article/Image/ImageEditView.vue') },
            { name: 'article-video-detail', path: '/article/video/detail/:articleId/:id', component: () => import(/* webpackChunkName: "article-video-detail" */ '../views/Article/Video/VideoDetailView.vue') },
            { name: 'article-video-edit', path: '/article/video/edit/:articleId/:id?', component: () => import(/* webpackChunkName: "article-video-edit" */ '../views/Article/Video/VideoEditView.vue') },
            { name: 'gallery', path: '/gallery', component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery/GalleryView.vue') },
            { name: 'gallery-detail', path: '/gallery/detail/:id', component: () => import(/* webpackChunkName: "gallery-detail" */ '../views/Gallery/GalleryDetailView.vue') },
            { name: 'gallery-edit', path: '/gallery/edit/:id?', component: () => import(/* webpackChunkName: "gallery-edit" */ '../views/Gallery/GalleryEditView.vue') },
            { name: 'gallery-gallery_entry-detail', path: '/gallery/gallery_entry/detail/:galleryId/:id', component: () => import(/* webpackChunkName: "gallery-gallery_entry-detail" */ '../views/Gallery/GalleryEntry/GalleryEntryDetailView.vue') },
            { name: 'gallery-gallery_entry-edit', path: '/gallery/gallery_entry/edit/:galleryId/:id?', component: () => import(/* webpackChunkName: "gallery-gallery_entry-edit" */ '../views/Gallery/GalleryEntry/GalleryEntryEditView.vue') },
            { name: 'subpage', path: '/subpage', component: () => import(/* webpackChunkName: "subpage" */ '../views/Subpage/SubpageView.vue') },
            { name: 'subpage-detail', path: '/subpage/detail/:id', component: () => import(/* webpackChunkName: "subpage-detail" */ '../views/Subpage/SubpageDetailView.vue') },
            { name: 'subpage-edit', path: '/subpage/edit/:id?', component: () => import(/* webpackChunkName: "subpage-edit" */ '../views/Subpage/SubpageEditView.vue') },
            { name: 'subpage-image-detail', path: '/subpage/image/detail/:subpageId/:id', component: () => import(/* webpackChunkName: "subpage-image-detail" */ '../views/Subpage/Image/ImageDetailView.vue') },
            { name: 'subpage-image-edit', path: '/subpage/image/edit/:subpageId/:id?', component: () => import(/* webpackChunkName: "subpage-image-edit" */ '../views/Subpage/Image/ImageEditView.vue') },
            // Prezentačný web

            // Číselníky
            { name: 'address_country', path: '/address_country', component: () => import(/* webpackChunkName: "address_country" */ '../views/AddressCountry/AddressCountryView.vue') },
            { name: 'address_country-detail', path: '/address_country/detail/:id', component: () => import(/* webpackChunkName: "address_country-detail" */ '../views/AddressCountry/AddressCountryDetailView.vue') },
            { name: 'address_country-edit', path: '/address_country/edit/:id?', component: () => import(/* webpackChunkName: "address_country-edit" */ '../views/AddressCountry/AddressCountryEditView.vue') },
            { name: 'legal_form', path: '/legal_form', component: () => import(/* webpackChunkName: "legal_form" */ '../views/LegalForm/LegalFormView.vue') },
            { name: 'legal_form-detail', path: '/legal_form/detail/:id', component: () => import(/* webpackChunkName: "legal_form-detail" */ '../views/LegalForm/LegalFormDetailView.vue') },
            { name: 'legal_form-edit', path: '/legal_form/edit/:id?', component: () => import(/* webpackChunkName: "legal_form-edit" */ '../views/LegalForm/LegalFormEditView.vue') },
            { name: 'document_type', path: '/document_type', component: () => import(/* webpackChunkName: "document_type" */ '../views/DocumentType/DocumentTypeView.vue') },
            { name: 'document_type-detail', path: '/document_type/detail/:id', component: () => import(/* webpackChunkName: "document_type-detail" */ '../views/DocumentType/DocumentTypeDetailView.vue') },
            { name: 'document_type-edit', path: '/document_type/edit/:id?', component: () => import(/* webpackChunkName: "document_type-edit" */ '../views/DocumentType/DocumentTypeEditView.vue') },
            { name: 'project_type', path: '/project_type', component: () => import(/* webpackChunkName: "project_type" */ '../views/ProjectType/ProjectTypeView.vue') },
            { name: 'project_type-detail', path: '/project_type/detail/:id', component: () => import(/* webpackChunkName: "project_type-detail" */ '../views/ProjectType/ProjectTypeDetailView.vue') },
            { name: 'project_type-edit', path: '/project_type/edit/:id?', component: () => import(/* webpackChunkName: "project_type-edit" */ '../views/ProjectType/ProjectTypeEditView.vue') },
            // Číselníky

            { name: 'mail_pattern', path: '/mail_pattern', component: () => import(/* webpackChunkName: "mail_pattern" */ '../views/MailPattern/MailPatternView.vue') },
            { name: 'mail_pattern-detail', path: '/mail_pattern/detail/:id', component: () => import(/* webpackChunkName: "mail_pattern-detail" */ '../views/MailPattern/MailPatternDetailView.vue') },
            { name: 'mail_pattern-edit', path: '/mail_pattern/edit/:id?', component: () => import(/* webpackChunkName: "mail_pattern-edit" */ '../views/MailPattern/MailPatternEditView.vue') },
            { name: 'print_pattern', path: '/print_pattern', component: () => import(/* webpackChunkName: "print_pattern" */ '../views/PrintPattern/PrintPatternView.vue') },
            { name: 'print_pattern-detail', path: '/print_pattern/detail/:id', component: () => import(/* webpackChunkName: "print_pattern-detail" */ '../views/PrintPattern/PrintPatternDetailView.vue') },
            { name: 'print_pattern-edit', path: '/print_pattern/edit/:id?', component: () => import(/* webpackChunkName: "print_pattern-edit" */ '../views/PrintPattern/PrintPatternEditView.vue') },
            { name: 'text_pattern', path: '/text_pattern', component: () => import(/* webpackChunkName: "text_pattern" */ '../views/TextPattern/TextPatternView.vue') },
            { name: 'text_pattern-detail', path: '/text_pattern/detail/:id', component: () => import(/* webpackChunkName: "text_pattern-detail" */ '../views/TextPattern/TextPatternDetailView.vue') },
            { name: 'text_pattern-edit', path: '/text_pattern/edit/:id?', component: () => import(/* webpackChunkName: "text_pattern-edit" */ '../views/TextPattern/TextPatternEditView.vue') },
            { name: 'setting', path: '/setting', redirect: '/setting/detail' },
            { name: 'setting-detail', path: '/setting/detail', component: () => import(/* webpackChunkName: "setting-detail" */ '../views/Setting/SettingDetailView.vue') },
            { name: 'setting-edit', path: '/setting/edit', component: () => import(/* webpackChunkName: "setting-edit" */ '../views/Setting/SettingEditView.vue') },
            { name: 'user', path: '/user', component: () => import(/* webpackChunkName: "user" */ '../views/User/UserView.vue') },
            { name: 'user-detail', path: '/user/detail/:id', component: () => import(/* webpackChunkName: "user-detail" */ '../views/User/UserDetailView.vue') },
            { name: 'user-edit', path: '/user/edit/:id?', component: () => import(/* webpackChunkName: "user-edit" */ '../views/User/UserEditView.vue') },
        ],
    },
    { name: 'catch', path: '/:catchAll(.*)*', redirect: '/' },
];

const router = createRouter({
    history: process.env.VUE_APP_ROUTER_MODE == 'hash' ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    // console.log('beforeEach', from, to);
    // return false;
    if (to.meta && to.meta.persistent && !!Object.keys(from.query).length) {
        let success = false;
        for (let i = 0; i < to.meta.persistent.length; i++) {
            const persistent = to.meta.persistent[i];
            if (from.query[persistent] && !to.query[persistent]) {
                to.query[persistent] = from.query[persistent];
                success = true;
            }
        }
        if (success) {
            next(to);
        } else {
            next();
        }
    } else {
        next();
    }
});
router.beforeResolve((to, from) => {
    // console.log('beforeResolve', from, to);
    // return false;
});
router.afterEach((to, from) => {
    // console.log('afterEach', from, to);
    // return false;
});

router.addRoutes = function (routes) {
    router.options.routes = routes;
    routes.forEach((route) => {
        router.addRoute(route);
    });
};
router.removeRoutes = function () {
    router.options.routes.forEach((route) => {
        router.removeRoute(route.name);
    });
    router.options.routes = [];
};
router.reload = function () {
    const path = window.location.href.replace(window.location.origin + router.options.history.base, '');
    router.replace(router.resolve(path));
};
router.login = function () {
    router.removeRoutes();
    router.addRoutes(mainRoutes);
    router.reload();
};
router.logout = function () {
    router.removeRoutes();
    router.addRoutes(loginRoutes);
    router.reload();
};

export default router;
